
/* Flean base imports */
@import url('https://fonts.googleapis.com/css?family=Bree+Serif|Lato:400,400i,700&display=swap');
$headings-font-family: 'Bree Serif', serif;
$font-family-base: 'Lato', serif;
$primary:#0097D7;

$mobile-header-background:white;
$mobile-header-color: $primary;
$bottom-footer-bg-color:$primary;
$secondary: #014587;
$call_to_action_bg: #F9A603;
$call_to_action_text: white;
$headings-color:$secondary;
$body-bg: #F8FCFF;
$footer-border-top: 1px solid lighten($primary,5);


/* Flean variables and Bootstrap overrides */
@import "../../flean_base_2019/sass/variables";

/* Bootstrap */
@import "../../flean_base_2019/sass/bootstrap";

/* Hamburger */
@import "../../flean_base_2019/sass/hamburger";

/* Vendor */
@import "../../flean_base_2019/sass/vendor";

/* Mixins */
@import "../../flean_base_2019/sass/mixins";

@mixin call_to_action($background: $call_to_action_bg, $important : null) {
  background: $background $important;
  color: $call_to_action_text $important;
  border: none $important;
  border-bottom: 3px solid darken($background, 5) $important;
  padding:.5em 1em;
  font-weight:bold $important;
  border-radius: $border-radius $important;
  &:hover {
    border-bottom: 3px solid darken($background, 10) $important;
    background: darken($background, 5) $important;
    color: $call_to_action_text $important;
    text-decoration:none $important;
  }
}
.btn {
  font-weight: bold;
}

h1, h2 ,h3, h4 {
  font-weight:normal !important;
}

#header > .header .logo img {
  max-height: 60px !important;
  max-width: 215px !important;
}

.mobile-header .container .logo-mobile.with-logo a img {
  max-height: 3.2rem !important;
  max-width: 160px !important;
}

/* Layout */
@import "../../flean_base_2019/sass/layout";

/* Components */
@import "../../flean_base_2019/sass/components";

.offer {
  .btn-primary {
    @include call_to_action;
  }
  .btn-secondary {
    @include call_to_action($primary);
  }
}

.discounts-custom {
  .inner {
    > div {
      position:absolute;
      top: -7px;
      left:-10px;
      height:73px;
      width:193px;
      background-size:contain !important;
      background-repeat:no-repeat !important;
      z-index:0;
      .column &{
        top:65px;
      }
      @include media-breakpoint-down(lg){
        width:193px;
        top: -7px;
        left:-10px;
        .column &{
          top:5px;
          left:auto;
          right:5px;
          width:120px;
        }
      }
      &.nl{
        background:url(../images/aanbieding.png);
      }
      &.de{
        background:url(../images/angebot.png);
      }
      &.last_minute.de,
      &.last_minute.nl{
        background:url(../images/last-minute.png);
      }
    }
  }
}

// below is styling specifically for the helper_rent_funnel module,
// which currently is only used bij FoV.

#header > .header .user-menu ul.nav {

  .menu-746 {

    a {
      border-radius: 5px;

      color: #fff;
      font-weight: bold;
      text-align: center;

      background: #f89c0e;

      &:hover {
        background: #e09503;
      }
    }
  }
}

.page-verhuren h1.title {
  max-width: 20em;
}

.wrapper {

  .intro {
    margin-bottom: 2em;
    @include media-breakpoint-up(lg) {
      max-width: 50%;
    }
    display: flex;
    padding-right: 1em;
    .image {
      padding-left: 1em;
      img {
        width: 91px;
      }
    }
  }

  .inner-wrapper {
    @include media-breakpoint-up(lg) {
      display: flex;
      gap: 2em;
    }
  }

  .left {
    flex: 1;
    margin-bottom: 2em;

    .intro {
      margin-bottom: 2em;
      display: flex;
      padding-right: 1em;
      p {
        flex: 1;
      }
      img {
        margin-left: 1em;
      }
    }

    form {
      padding: 2em;
      background-color: #d6e4f3;
      border-radius: 5px;

      legend {
        margin-bottom: 1.5rem;
      }

      .fieldset-legend {
        padding-bottom: 0.5em;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        color: #014587;
        font-family: "Bree Serif", serif;
        font-size: 1.1em;
        line-height: 1.2;
      }

      .form-item-agree {
        position: relative;
        display: flex;
        align-items: center;

        margin-top: 0;
        line-height: 1;
        @include clearfix;

        input {
          min-height: 18px;
          min-width: 18px;
          height: 18px;
          width: 18px;

          margin-right: 10px;
          margin-left: 1px;
        }

        label {
          font-weight: normal;
          line-height: 1.5;
        }
      }

      .agree {
        @include clearfix;
        @include media-breakpoint-down(xs) {
          margin: 30px 0;
        }

        .form-item {
          margin-top: 0;

          a {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .right {
    flex: 1;

    .quote {
      background-color: #fff;
      padding: 2em;

      blockquote {
        quotes: "\201C""\201D""\2018""\2019";
        border-left: 0;

        &:before {
          color: $primary;
          content: open-quote;
          font-size: 4em;
          line-height: 0.1em;
          margin-right: 0.25em;
          vertical-align: -0.4em;
        }
        &:after {
          color: $primary;
          content: close-quote;
          font-size: 4em;
          line-height: 0.1em;
          margin-left: 0.25em;
          vertical-align: -0.6em;
        }
      }

      .quote--profile {

        .quote--image {
          width: 85px;
          float: left;
          margin-right: 1rem;
        }

        .quote--name {
          display: block;
          font-weight: bold;
        }

        .quote--text {

        }
      }
    }
  }

  ul.checkboxes {
    margin-top: 1rem;
    margin-left: 1.6rem;
    margin-bottom: 0;
    padding: 0;
    list-style-type: none;

    li {
      &:before {
        content: '';
        width: 1rem;
        height: 1rem;
        display: inline-block;
        margin-right: 0.5rem;
        vertical-align: middle;
        mask-image: url(#{$base_theme_path}/images/icons/general/check.svg);
        background-color: green;
        margin-left: -1.6rem;
      }

      font-size: 0.9rem;

    }
  }


}


//region rent funnel general

$rf-orange: #f89c0e;
$rf-orange-dark: #e09503;
$rf-dark-blue: #18468b;
$rf-blue: #0097d7;
$rf-light-blue: #f8fcff;

.rf-bg {
  padding: 50px 0;

  background: #fff;

  &--double-padding {
    padding: 100px 0;
  }

  &--image {
    padding: 50px 0 0;

    background-position: center;
    background-repeat: no-repeat;
    background-size: 110%;

    @media (max-width: 1700px) {
      background-size: cover;
    }
  }

  &--blue {
    background: $rf-light-blue;
  }

  &--lighter-blue {
    background: #a2daee;
  }

  &--dark-blue {
    background: $rf-dark-blue;
  }
}

.rf-title {
  color: $rf-blue;

  @include media-breakpoint-down(sm){
    font-size: 24px;
  }

  @include media-breakpoint-down(xs){
    font-size: 20px;
  }
}

.rf-block-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 280px;

  padding-left: 20px;
  padding-right: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  color: $rf-blue;
  font-size: 17px;
  font-weight: bold;

  background: #fff;
  box-shadow: 2px 2px 1px #eee;

  @include media-breakpoint-down(xs){
    width: 180px;

    padding: 20px 10px;

    font-size: 15px;
  }

  img {
    height: 16px;
  }
}

.rf-btn-primary {
  padding: 10px 30px;
  border: $rf-orange;

  background: $rf-orange;

  &:hover, &:active {
    border: $rf-orange-dark !important;

    background: $rf-orange-dark !important;
  }
  &:focus, &:active:focus {
    box-shadow: none !important;
  }
}

.rf-text-link {
  color: $rf-blue;
  text-decoration: underline;
}

.rf-bent-arrow-links {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px 100px 0;

  background-repeat: no-repeat;

  @include media-breakpoint-down(xs){
    background-position-x: 40px;
    background-size: 40px;
  }

  .rf-btn-primary {
    margin-bottom: 20px;

    &--download {
      position: relative;

      padding: 10px 60px 10px 10px;

      img {
        position: absolute;
        height: 15px;
        width: 15px;
        top: 15px;
        right: 25px;
      }
    }
  }

  span {
    text-align: center;
  }
}

.rf-overview-comments {
  display: flex;
  flex-direction: column;
  align-items: center;


  .rf-title {
    margin-bottom: 50px;

    text-align: center;
  }

  > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .rf-comment {
      width: 48%;

      @include media-breakpoint-down(sm){
        width: 100%;

        margin-bottom: 30px;
      }

      padding: 30px;

      background: $rf-light-blue;

      &:first-of-type {
        margin-right: 4%;
      }

      p {
        position: relative;

        &:before {
          content: '“';
          position: absolute;
          top: -30px;
          left: -20px;

          font-size: 50px;
          color: $rf-blue;
        }

        &:after {
          content: '”';
          position: absolute;
          right: -20px;

          font-size: 50px;
          color: $rf-blue;
        }
      }

      .rf-comment-profile-image {
        display: flex;

        img {
          border-radius: 50%;
          height: 85px;
          width: 85px;
        }

        div {
          display: flex;
          flex-direction: column;
          justify-content: center;

          padding-left: 20px;

          .rf-comment-name {
            font-weight: bold;
          }

          .rf-comment-about {

            @include media-breakpoint-down(xs){
              font-size: 12px;
            }
          }

          .rf-comment-about a {
            color: #000;
            text-decoration: underline;

            @include media-breakpoint-down(xs){
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.rf-persuasive-texts {
  display: flex;
  flex-direction: column;
  align-items: center;

  .rf-title {
    width: 900px;

    text-align: center;

    @include media-breakpoint-down(md){
      width: auto;
    }

    @include media-breakpoint-down(xs){
      margin-bottom: 10px;
    }

    &--particular {
      margin-bottom: 50px;

      @include media-breakpoint-down(xs){
        margin-bottom: 30px;
      }
    }
  }

  .rf-persuasive-text-blocks-wrap {
    width: 46%;

    @include media-breakpoint-down(md){
      width: 100%;
    }

    .rf-persuasive-text-block {
      display: flex;
      align-items: center;

      &--private {
        margin-bottom: 30px;
      }

      &--business {
        @include media-breakpoint-down(lg) {
          margin-bottom: 30px;
        }
        @include media-breakpoint-down(md) {
          margin-bottom: 0;
        }
        @include media-breakpoint-down(xs) {
          margin-top: 30px;
        }
      }

      img {
        height: 106px;
        width: 120px;

        margin: 30px 30px 30px 0;

        @include media-breakpoint-down(xs){
          display: none;
        }
      }

      div {
        @include media-breakpoint-down(xs) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        h5 {
          margin-bottom: 10px;

          color: #000;
          font-size: 17px;

          @include media-breakpoint-down(xs) {
            margin-bottom: 0;
          }
        }

        .rf-persuasive-text-res-img {
          display: none;
          width: 100px;
          height: 88px;

          margin: 0;

          @include media-breakpoint-down(xs) {
            display: block;
          }
        }

        p {

          a {
            color: $rf-blue;
          }
        }
      }
    }
  }

  .rf-bent-arrow-links {
    margin-top: 50px;

    img {
      right: 10px;
    }
  }
}

.rf-persuasive-logos-wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  @include media-breakpoint-down(xs){
    display: none;
  }

  .rf-persuasive-logo {
    display: block;

    @include media-breakpoint-down(md){
      margin: 10px 20px;
    }

    &--bb {

      img {
        width: 130px;

        @include media-breakpoint-down(xs){
          margin: 30px 0 10px;
        }
      }
    }

    &--hh {

      img {
        width: 150px;

        @include media-breakpoint-down(md){
          width: 170px;
        }

        @include media-breakpoint-down(xs){
          margin: 0;
        }
      }
    }

    &--sd {

      img {
        width: 180px;

        @include media-breakpoint-down(xs){
          margin: 20px 0 40px;
        }
      }
    }

    &--vpdh {

      img {
        width: 170px;

        @include media-breakpoint-down(md){
          width: 190px;
        }

        @include media-breakpoint-down(xs){
          margin: 10px 0 35px;
        }
      }
    }

    &--wvpg {
      cursor: default;

      img {
        width: 150px;

        @include media-breakpoint-down(md){
          width: 170px;
        }
      }
    }
  }
}

//endregion


//region rent funnel overview

.rf-overview-intro {
  display: flex;
  justify-content: space-between;

  .rf-overiew-intro-img {
    width: 45%;

    border-radius: 20px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: 30% 50%;

    @include media-breakpoint-down(lg){
      width: 35%;
    }

    @include media-breakpoint-down(md){
      display: none;
    }
  }

  &-text-wrap {
    max-width: 45%;

    @include media-breakpoint-down(lg){
      max-width: 60%;
    }

    @include media-breakpoint-down(md){
      max-width: none;
    }

    .rf-title {
      margin-bottom: 30px;
    }

    p {
      font-size: 17px;
      font-weight: bold;
    }

    .rf-look-at-more {
      font-size: 14px;
      font-weight: bold;
    }

    .rf-intro-link {
      display: flex;

      margin-top: 20px;

      text-decoration: none;

      &:first-of-type {
        margin-top: 25px;
      }

      .rf-intro-link-img {
        height: 60px;
        width: 90px;

        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 2px 2px 1px #eee;
      }
    }

    .rf-direct-information {
      display: inline-block;

      margin-top: 30px;

      color: #94989c;
      font-size: 14px;

      span {
        text-decoration: underline;
      }
    }
  }
}

.rf-select-acc-type {
  display: flex;
  flex-direction: column;
  align-items: center;

  .rf-title {
    max-width: 700px;

    margin-bottom: 30px;

    text-align: center;
  }

  .rf-type-option {
    display: inline-block;

    margin-bottom: 15px;

    font-size: 16px;
    font-weight: bold;
  }

  > div {
    display: flex;
    justify-content: space-between;
    width: 850px;

    @include media-breakpoint-down(md){
      flex-direction: column;
      align-items: center;
    }

    .rf-acc-type {
      display: flex;
      flex-direction: column;

      text-decoration: none;

      @include media-breakpoint-down(md){
        margin-bottom: 30px;
      }

      img {
        height: 212px;
        width: 402px;

        @include media-breakpoint-down(sm){
          height: 150px;
          width: 280px;
        }
      }

      .rf-block-link {
        justify-content: flex-start;
        width: 100%;

        box-shadow: 0 2px 2px #eee;

        @include media-breakpoint-down(sm){
          font-size: 15px;
        }

        span:first-child {
          display: inline-block;

          margin-right: 10px;
        }

        img {
          height: 16px;
          width: auto;

          margin-right: 10px;
        }
      }
    }
  }
}

//endregion

.rf-private-intro {
  display: flex;
  justify-content: space-between;

  img {
    width: 50%;

    @include media-breakpoint-down(md){
      display: none;
    }
  }

  .rf-private-intro-text-wrap {
    width: 48%;

    @include media-breakpoint-down(md){
      width: 100%;
    }

    .rf-title {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 30px;

      font-size: 17px;
      font-weight: bold;
    }

    .rf-btn-primary {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 280px;

      img {
        height: 16px;
        width: 16px;
      }
    }

    .rf-private-promote {
      margin-bottom: 10px;

      @include media-breakpoint-down(sm){
        font-size: 14px;
      }

      img {
        height: 11px;
        width: 14px;

        margin-right: 10px;

        @include media-breakpoint-down(md){
          display: inline-block;
        }
      }
    }

    .rf-private-intro-link-wrap {
      margin-top: 30px;

      @include media-breakpoint-down(sm){
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .rf-btn-primary {
        margin-right: 20px;

        @include media-breakpoint-down(sm){
          margin-right: 0;

          margin-bottom: 20px;
        }
      }
    }
  }
}

.rf-steps {
  display: flex;
  flex-direction: column;
  align-items: center;

  .rf-title {
    margin-bottom: 50px;

    text-align: center;
  }

  .rf-step {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 100px;

    @include media-breakpoint-down(xs){
      margin-bottom: 50px;
    }

    &--with-link {
      @include media-breakpoint-down(sm){
        display: block;
      }
    }

    .rf-step-desktop-img {
      width: 44%;

      @include media-breakpoint-down(sm){
        display: none;
      }
    }


    .rf-step-desktop-img-link {
      width: 44%;

      .rf-step-desktop-img {
        width: 100%;

        @include media-breakpoint-down(sm){
          display: none;
        }
      }
    }


    div {
      width: 44%;

      @include media-breakpoint-down(sm){
        width: 100%;
      }

      h5 {
        margin-bottom: 15px;

        color: #000;
        font-size: 17px;
      }

      h3 {
        margin-bottom: 30px;

        color: $rf-blue;
        font-size: 28px;

        @include media-breakpoint-down(sm){
          display: block;
          width: 80%;

          margin-bottom: 10px;
        }
      }

      .rf-step-mobile-img {
        display: none;

        @include media-breakpoint-down(sm){
          display: block;
          width: 80%;

          margin-bottom: 10px;
        }

        @include media-breakpoint-down(xs){
          width: 100%;
        }
      }

      .rf-step-mobile-img-link {
        width: 100%;

        .rf-step-mobile-img {

        }
      }
    }
  }
}

.rf-packs {
  display: flex;
  flex-direction: column;
  align-items: center;

  .rf-title {
    margin-bottom: 50px;
  }

  .rf-pack-attributes {
    width: 28%;

    padding: 91px 0 0;

    @include media-breakpoint-down(lg){
      display: none;
    }

    li {
      display: flex;
      align-items: center;
      height: 50px;

      padding-left: 10px;

      font-size: 14px;
      font-weight: bold;

      list-style: none;
    }
    li:nth-child(odd) {
      background: #fff;
    }
    li:nth-child(even) {
      background: #eef8ff;
    }

  }

  .rf-packs-wrap {
    display: flex;
    justify-content: space-between;
    width: 72%;

    @include media-breakpoint-down(lg){
      width: 100%;
    }

    @include media-breakpoint-down(sm){
      flex-wrap: wrap;
    }

    .rf-full-pack {
      width: 50%;

      @include media-breakpoint-down(sm){
        width: 100%;
      }

      .rf-pack-name-price {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        color: #fff;
        font-weight: bold;
        text-align: center;

        @include media-breakpoint-down(md){
          height: 110px;
        }

        @include media-breakpoint-down(sm){
          height: auto;
        }

        &--light {
          background: #4dcaff;
        }

        &--normal {
          background: #0097d7;
        }

        &--dark {
          background: #18468b;
        }

        .rf-pack-name {
          font-size: 20px;
        }

        .rf-pack-price {
          font-size: 14px;
        }
      }

      .rf-pack {
        padding: 0;
        border: solid 1px #e0e0e0;
        border-top: none;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        li {
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;

          padding: 0 20px;

          font-size: 14px;
          text-align: center;

          list-style: none;

          @include media-breakpoint-down(lg){
            justify-content: flex-start;

            text-align: left;
          }

          @include media-breakpoint-down(lg){
            height: 70px;
          }

          @include media-breakpoint-down(sm){
            height: 50px;
          }

          img {
            height: 11px;
            width: 14px;
          }

          .img-cross {
            display: none;
            height: 11px;
            width: 11px;
          }

          span {
            display: none;

            @include media-breakpoint-down(lg){
              display: block;

              margin-left: 20px;
            }
          }

          .text-without-img {
            margin-left: 0;
          }

          .only-desktop-text {
            display: inline;

            @include media-breakpoint-down(lg){
              display: none;
            }
          }

          .rf-btn-primary {
            display: flex;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-down(md){
              padding: 10px;
            }

            img {
              height: 16px;
              width: 16px;

              margin-left: 20px;

              @include media-breakpoint-down(lg){
                margin-left: 30px;
              }

              @include media-breakpoint-down(md){
                margin-left: 20px;
              }
            }
          }
        }
        li:nth-child(odd) {
          background: #fff;
        }
        li:nth-child(even) {
          background: #eef8ff;
        }
        li:last-child {
          padding: 40px 20px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;

          .rf-btn-primary {
            position: relative;
            width: 100%;

            @include media-breakpoint-down(sm){
              width: 200px;
            }

            span {
              position: absolute;
              display: inline-block;
              right: 25px;
            }
          }
        }
      }
    }
  }

  .rf-guarantee-block {
    display: flex;

    margin-top: 50px;

    img {
      height: 350px;

      @include media-breakpoint-down(md){
        display: none;
      }
    }

    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      //height: 250px;
      gap: 40px;
      padding: 40px;
      border: solid 1px #e0e0e0;
      border-radius: 10px;
      // this styling is for if the image in this block has to be shown again (border-radius: 10px has to be removed then).
      //border-left: none;
      //border-top-right-radius: 10px;
      //border-bottom-right-radius: 10px;

      background: #fff;

      @include media-breakpoint-down(md){
        height: auto;

        padding: 40px;

        border-left: solid 1px #e0e0e0;
        border-radius: 10px;
      }

      h5 {
        margin-bottom: 10px;

        color: #000;
      }

      h3 {
        margin-bottom: 20px;

        color: $rf-blue;
      }

      p {
        margin-bottom: 20px;

        font-size: 14px;
      }

      a {
        color: $rf-blue;
        text-decoration: underline;
      }

      .content-wrapper {

      }
    }
  }
}

.rf-faq {
  display: flex;
  flex-direction: column;
  align-items: center;

  .collapsible {
    cursor: pointer;
  }

  .collapsible-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  .rf-title {
    margin-bottom: 50px;
  }

  .rf-faq-columns {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .rf-faq-column {
      width: 44%;

      @include media-breakpoint-down(sm){
        width: 100%;

        margin-bottom: 30px;
      }

      h5 {
        color: #000;
        font-size: 18px;
      }

      p {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.rf-call-to-action {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    width: 740px;

    color: #fff;
    text-align: center;

    @include media-breakpoint-down(sm){
      width: auto;
      font-size: 24px;
    }
  }

  .rf-bent-arrow-links {
    background-size: 60px;

    @include media-breakpoint-down(sm){
      background-size: 40px;
      background-position-y: 10px;
    }
  }
}


.rf-business-intro {
  width: 50%;

  color: #fff;

  @include media-breakpoint-down(md){
    width: 100%;
  }

  h5 {
    margin-bottom: 20px;

    color: #fff;
    font-weight: 400;
  }

  h1 {
    margin-bottom: 30px;

    color: #fff;
  }

  p {
    margin-bottom: 30px;

    font-weight: 600;
  }

  div {
    margin-bottom: 20px;

    font-size: 14px;
    font-weight: 400;

    img {
      margin-right: 10px;
    }
  }

  .rf-btn-primary {
    position: relative;

    padding: 10px 30px 10px 50px;

    img {
      position: absolute;
      top: 14px;
      left: 20px;
      height: 16px;
    }
  }
}

.rf-business-intro-bg-overlay {
  margin-top: 50px;

  background: rgba(0, 0, 0, 0.2);

  .rf-business-intro-bg-overlay-content {
    display: flex;
    align-items: center;

    padding: 10px 0;

    img {
      height: 45px;

      margin-right: 10px;
    }

    span {
      color: #fff;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
}

.rf-business-promotion {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    width: 800px;

    margin-bottom: 20px;

    text-align: center;

    @include media-breakpoint-down(md){
      width: auto;
    }
  }

  h5 {
    margin-bottom: 50px;

    color: #000;
    font-size: 18px;
    text-align: center;
  }

  h4 {
    width: 600px;

    color: #000;
    font-size: 20px;

    @include media-breakpoint-down(sm){
      width: auto;

      text-align: center;
    }
  }

  p {
    width: 600px;

    margin-bottom: 50px;

    @include media-breakpoint-down(sm){
      width: auto;
    }
  }

  .rf-bent-arrow-links {
    margin-top: 50px;

    .rf-btn-primary {
      position: relative;
      padding-right: 60px;

      img {
        position: absolute;
        top: 15px;
        right: 25px;
        height: 16px;
      }
    }
  }
}

img.check-green {
  width: 15px;
}

img.check-white {
  width: 15px;
}

.rf-business-distinction {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    display: inline-block;

    margin-bottom: 45px;
    padding: 5px;

    color: #fff;
    font-size: 30px;
    font-weight: bold;
    text-align: center;

  }

  .rf-business-distinction-reasons {
    display: flex;
    justify-content: space-between;
    width: 900px;

    @include media-breakpoint-down(md){
      justify-content: center;
    }

    @include media-breakpoint-down(sm){
      flex-direction: column;
      align-items: center;
    }

    .rf-business-distinction-reason {
      position: relative;
      width: 200px;

      background-color: #fff;
      color: #004493;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      padding: 80px 20px 20px 20px;
      border-radius: 20px;


      @include media-breakpoint-down(md){
        margin: 0 15px;
      }

      @include media-breakpoint-down(sm){
        margin: 0 0 90px;
      }

      &:before {
        position: absolute;
        top : 0;
        left: 0;
        right: 0;

        color: #004493;
        font-size: 60px;
      }
      &--1 {
        &:before {
          content: '1';
        }
      }
      &--2 {
        &:before {
          content: '2';
        }
      }
      &--3 {
        &:before {
          content: '3';
        }
      }

      p {
        position: relative;

        margin-bottom: 0;
      }
    }
  }
}

.rf-business-services {
  display: flex;
  flex-direction: column;
  align-items: center;

  .rf-title {
    width: 600px;

    text-align: center;

    @include media-breakpoint-down(lg){
      width: auto;
    }
  }

  h5 {
    margin-bottom: 50px;

    color: black;
    font-size: 18px;
    text-align: center;
  }

  .rf-business-service-summation {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media-breakpoint-down(lg){
      justify-content: space-around;
    }


    .rf-business-service {
      display: flex;
      flex-direction: column;
      width: 360px;

      margin-bottom: 30px;
      padding: 20px 20px 10px;
      border-radius: 5px;

      background: #fff;
      box-shadow: 0 1px 2px 1px #e0e0e0;

      @include media-breakpoint-down(md){
        width: 320px;
      }

      @include media-breakpoint-down(sm){
        width: 360px;

        margin: 20px;
      }

      @include media-breakpoint-down(xs){
        width: 300px;

        margin: 20px;
      }

      h3 {
        margin-bottom: 20px;

        color: $rf-blue;
        font-size: 22px;
      }

      span {
        display: inline-block;

        margin-bottom: 20px;

        @include media-breakpoint-down(xs){
          font-size: 14px;
        }

        img {
          margin-right: 10px;
        }
      }
    }
  }
}

.rf-business-customer-review {
  display: flex;

  margin-top: 50px;
  margin-bottom: 50px;

  .rf-business-customer-review-image {
    width: 40%;
    border-radius: 40px;
    margin-right: 50px;

    @include media-breakpoint-down(lg){
      height: 50%;
    }

    @include media-breakpoint-down(sm){
      display: none;
    }
  }

  .rf-business-review {
    color: #fff;

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
    }

    .rf-business-review-case {
      display: block;
      width: 120px;

      margin-bottom: 80px;
      padding: 5px 10px;

      color: #fff;
      font-size: 20px;
      font-weight: bold;
      text-align: center;

      background: #0097d3;

      @include media-breakpoint-down(sm) {
        align-self: center;
      }
    }

    p {
      position: relative;

      margin-bottom: 30px;

      font-weight: bold;
      font-size: 20px;
      line-height: 40px;

      @include media-breakpoint-down(sm){
        width: 90%;

        margin: 0 auto 30px;

        text-align: center;
      }

      @include media-breakpoint-down(xs){
        font-size: 16px;
      }

      &:before {
        content: '“';
        position: absolute;
        top: -30px;

        font-size: 50px;
        color: #7c96bd;
      }

      &:after {
        content: '”';
        position: absolute;
        right: -20px;
        bottom: -30px;

        font-size: 50px;
        color: #7c96bd;
      }
    }

    .rf-business-review-person {
      display: flex;

      margin-bottom: 50px;

      img {
        height: 45px;
        width: 45px;

        margin-right: 20px;
      }

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .rf-business-review-person-name {
          font-size: 14px;
          font-weight: bold;
        }

        .rf-business-review-person-park {
          font-size: 14px;
        }
      }
    }

    .rf-btn-primary {
      position: relative;

      padding: 10px 60px 10px 10px;

      @include media-breakpoint-down(sm) {
        align-self: center;
      }

      img {
        position: absolute;
        height: 15px;
        width: 15px;
        top: 15px;
        right: 10px;
      }
    }
  }
}

.rf-business-about {
  display: flex;
  flex-direction: column;
  align-items: center;

  .rf-title {
    margin-bottom: 50px;
  }

  .rf-business-about-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;

    @include media-breakpoint-down(md){
      justify-content: center;
    }

    .rf-business-about-texts {
      width: 46%;

      @include media-breakpoint-down(md){
        width: 100%;
      }

      .rf-business-about-text {
        margin-bottom: 30px;

        &--intro {
          font-weight: bold;
        }
      }

      .rf-business-about-more-information {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-weight: bold;
        }

        a {
          color: $rf-blue;
          text-decoration: underline;

          &:hover {
            color: #001e3b;
          }
        }

        img {
          height: 80px;
          width: 80px;

          margin-left: 30px;

          @include media-breakpoint-down(xs){
            height: 60px;
            width: 60px;
          }
        }
      }
    }

    .rf-business-about-img {
      display: flex;
      flex-direction: column;
      width: 46%;

      img {
        width: 100%;

        border-radius: 20px;
      }

      span {
        font-style: italic;
      }

      @include media-breakpoint-down(md){
        margin-bottom: 50px;
      }

      @include media-breakpoint-down(sm){
        width: 70%;
      }

      @include media-breakpoint-down(xs){
        width: 300px;
      }
    }
  }
}

.rf-business-form-wrap {
  display: flex;
  align-items: center;

  img {
    width: 35%;
    height: 35%;

    margin-right: 50px;

    @include media-breakpoint-down(md){
      display: none;
    }
  }

  .rf-business-form {
    width: 50%;

    color: #fff;

    @include media-breakpoint-down(md){
      width: 100%;
    }

    h2 {
      margin-bottom: 30px;

      color: #fff;
    }

    #helper-rent-funnel-business-brochure-form {

      .fieldset-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .form-type-textfield {
          width: 48%;

          @include media-breakpoint-down(xs){
            width: 100%;
          }
        }

        .form-type-radio {
          margin-bottom: 0;

          label {
            font-weight: 400;
          }
        }
      }

      .rf-form-submit-wrap {
        display: inline-block;
        position: relative;

        .form-submit {
          padding-right: 50px;
          border: none;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 12px;
          right: 18px;
          height: 15px;
          width: 15px;

          background-image: url(#{$base_theme_path}/images/icons/general/download-icon.png);
          background-size: 15px 15px;
        }
      }

      .checkboxes {
        margin: 10px 0 0;
        padding: 0;

        color: #97accb;
        list-style: none;

        a {
          color: #97accb;
          text-decoration: underline;
        }
      }
    }
  }
}
